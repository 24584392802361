<template>
  <div class="content">
    <div class="search-box">
      <input id="tipinput" v-model="input" type="text" class="tipinp" />
    </div>
    <div class="mapcss">
      <div ref="map" class="cont" id="map-container"></div>
    </div>
    <div v-if="activeName == 'second'" class="input-card">
      <el-button size="mini" class="btn" @click="createPolygon()">
        新建
      </el-button>
      <el-button size="mini" class="btn" @click="openall"> 编辑 </el-button>
      <el-button size="mini" class="btn" @click="polyclose">完成</el-button>
      <el-button size="mini" class="btn" @click="removepol">移除</el-button>
    </div>

    <!-- <div>
      <span slot="footer">
        <el-button size="medium">取消</el-button>
        <el-button type="primary" size="medium">确定</el-button>
      </span>
    </div> -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "6a5a69a56b383204455176e1c99c1f75",
};
export default {
  props: ["activeName"],
  data() {
    return {
      show: "",
      map: null,
      auto: null,
      input: "",
      center: [116.406399, 39.96128],
      lnglat: [],
      positions: [],
      polygon1: null,
      lngone:[]
    };
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    //  todo 1.新增地址 初始化（单次引用 ） new AMap.PolygonEditor(this.map, null);
    //  todo 2.搜索地图地址 调用 new AMap.PlaceSearch
    //  todo 3.更新中心坐标点 map.add("搜索经纬度") map.setFitView() 缩放地图中心点
    this.initmap();
  },
  methods: {
    editone(val) {
      console.log(val, "单点选择");
      setTimeout(() => {
        this.positions = val;
        this.center = val;
        this.setMarker();
      }, 3000);
      this.initmap();
    },
    openadd() {
      setTimeout(() => {
        this.positions = [];
        this.center = [116.406399, 39.96128];
      });
      this.setMarker();
      this.initmap();
    },

    edit1(val) {
      this.positions =val;
     this.center = this.positions;
      setTimeout(() => {
        this.map.add([this.polygon1]);
        this.setMarker();
      }, 1000);
      this.initmap();
    },

    edit(val) {
      this.positions = val;
      // 设置中心点
      this.center = this.positions[0];
      setTimeout(() => {
        this.map.add([this.polygon1]);
        this.setMarker();
      }, 1000);
      this.initmap();
    },
    //新建
    createPolygon() {
      //  this.map.clearMap();
      // this.positions = [];
      this.polyEditor.setTarget();
      this.polyEditor.open();
    },
    //编辑
    openall() {
      this.polyEditor.open();
    },
    //完成
    polyclose() {
      this.polyEditor.close();
    },
    //移除
    removepol() {
      this.map.clearMap();
    },
    setMarker() {
      var marker = new AMap.Marker({
        position: this.center,
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
      });
      this.map.add(marker);
      this.map.setFitView();
    },

    searchMap() {
      // 搜索框自动完成类
      this.auto = new AMap.AutoComplete({
        input: "tipinput", // 使用联想输入的input的id
      });
      //构造地点查询类
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
        autoFitView: true,
      });
      this.auto.on("select", this.selectSite);
    },
    selectSite(e) {
      this.lnglat = [e.poi.location.lng, e.poi.location.lat];
      console.log(this.lnglat);
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name);
      this.$emit("ClickD", e.poi,this.lnglat);
    },

    initmap() {
      AMapLoader.load({
        key: "c2868746f6d0d525fc35b1f377e683c2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.Driving",
          "AMap.PolygonEditor",
          "AMap.PlaceSearch",
          "AMap.MouseTool", 
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => { 
        this.map = new AMap.Map("map-container", {
          resizeEnable: true,
          zoom: 11, //初始化地图级别
          center: this.center,
        });
        console.log(this.center, "初始化中心店");
        this.searchMap();
        //构造地点查询类
        console.log(this.positions);
        this.polygon1 = new AMap.Polygon({
          path: this.positions,
          strokeColor: "#FF33FF",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.5,
          fillColor: "#1791fc",
          zIndex: 50,
        });
        console.log(this.polygon1, "绘制");
        this.polyEditor = new AMap.PolygonEditor(this.map, this.polygon1);
        console.log(this.polyEditor, "polyEditor");
        this.polyEditor.on("adjust", (e) => {});
        this.polyEditor.on("add", (data) => {
          console.log("绘制图add:", data);
          var polygon = data.target;
          this.polyEditor.addAdsorbPolygons([polygon]);
          polygon.on("dblclick", (e) => {
            console.log(e);
            this.polyEditor.setTarget(polygon);
            this.polyEditor.open();
          });
        });
        this.polyEditor.on("end", (e) => {
          console.log("绘制end：", e);
          let a = e.target.getPath();
          let b = [];
          for (let i = 0; i < a.length; i++) {
            b.push([a[i].lng, a[i].lat]);
          }
          console.log(b, "经纬度");
          this.$emit("cliad", b);
        });
      });
    },
  },
};
</script>

<style scoped>
.content {
  position: relative;
}
.inp {
  height: 30px;
}
.search-box {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 9999;
}
.tipinp {
  height: 20px;
}
.input-card {
  margin-top: 15px;
}
.cont {
  height: 200px;
}
</style>