<template>
  <div class="districon">
    <div class="btinput">
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input2"
        @change.native="getlist()"
      >
      </el-input>
    </div>
    <div class="btn">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          fixed
          prop="companyName"
          align="center"
          label="物流公司"
        >
        </el-table-column>
        <el-table-column
          prop="latLongStr"
          label="详细地址/经纬度"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.addressInfo">{{
              scope.row.addressInfo
            }}</span>
            <span v-else>{{ scope.row.latLongStr }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="range" label="揽收范围" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.range">{{ scope.row.range }}米</span>
            <span v-else>{{ scope.row.range }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后更新时间" align="center">
        </el-table-column>
        <el-table-column prop="companySite" label="站点名称" align="center">
        </el-table-column>
        <el-table-column prop="userName" label="操作人" align="center">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="delet(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
    >
      <div class="eltab">
        <el-tabs
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          v-model="activeName"
          @tab-click="activechange"
        >
          <el-tab-pane label="选择地点" name="first">
            <!-- 搜索 -->
          </el-tab-pane>
          <el-tab-pane label="绘制范围" name="second"> </el-tab-pane>
        </el-tabs>
      </div>
      <div class="amap" v-if="reflash">
        <div id="container">
          <serch
            ref="serc"
            :activeName="activeName"
            @ClickD="editremark"
            @cliad="lngdit"
          ></serch>
        </div>
      </div>
      <div class="tabdiv">
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
          <el-form-item label="物流公司">
            <el-select v-model="form.name" placeholder="请选择物流公司">
              <el-option
                v-for="item in comp"
                :label="item.name"
                :value="item.companyId"
                :key="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="站点名称" prop="zdname">
            <el-input
              size="small" 
              maxlength="100"
              v-model="form.zdname"
              @input="change($event)"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="activeName == 'first'" label="详细地址">
            <span>{{ form.remark }}</span>
          </el-form-item>
          <el-form-item
            v-if="activeName == 'first'"
            prop="number"
            label="揽收范围"
          >
            <el-select v-model="form.number" placeholder="请选择揽收范围">
              <el-option
                v-for="item in quyu"
                :label="item.lable"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeName !== 'first'" label="经纬度">
            <span>{{ form.lngt }}</span>
          </el-form-item>
          <el-form-item>
             <el-button type="primary" @click="query('form')">确认</el-button>
            <el-button  @click="resetForm('form')"
              >取消</el-button
            >
           
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  GetCollectFenceList,
  AddCollectFence,
  UpCollectFence,
  GetLogisticsCompanyLists,
} from "../../api/nologistics";
import { Delete } from "../../api/prints";
import serch from "../../components/serch/index.vue";
export default {
  components: {
    serch,
  },
  data() {
    return {
      id: 0,
      title: "新增地址",
      dialogVisibletwo: false,
      addtype: 0,
      comp: [],
      quyu: [
        {
          lable: "100米",
          value: 100,
        },
        {
          lable: "200米",
          value: 200,
        },
        {
          lable: "300米",
          value: 300,
        },
        {
          lable: "400米",
          value: 400,
        },
        {
          lable: "500米",
          value: 500,
        },
      ],
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      dialogVisible: false,
      form: {
        name: "",
        remark: "",
        number: "",
        lngt: "",
        lngone: "",
        zdname: "",
      },
      value: "",
      input2: "",
      activeName: "first",
      rules: {
        zdname: [
          { required: true, message: "请输入站点名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入平台名称", trigger: "blur" }],
        number: [
          { required: true, message: "请选择揽收范围", trigger: "change" },
        ],
      },
      reflash: true,
    };
  },
  created() {
    this.getlist();
    this.getcomp();
  },
  watch: {
    activeName: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, "val-----");
      },
    },
  },
  methods: {
    change(e) {
      this.$forceUpdate();
    },
    handleClosetwo() {
      this.dialogVisibletwo = false;
    },

    getcomp() {
      GetLogisticsCompanyLists().then((res) => { 
        console.log(res);
        this.comp = res.data;
      });
    },

    resetForm() {
      this.dialogVisible = false;
      this.form = {
        name: "",
        remark: "",
        number: "",
        lngt: "",
      };
    },
    //提交
    query(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addtype == 0) {
            if (this.activeName == "first") {
              let data = {
                companyId: this.form.name || 0,
                addressInfo: this.form.remark,
                range: this.form.number,
                latLongStr: JSON.stringify(this.form.lngone),
                CompanySite: this.form.zdname,
              };
              this.addCollectFence(data);
            } else {
              let data = {
                companyId: this.form.name || 0,
                CompanySite: this.form.zdname,
                latLongStr: JSON.stringify(this.form.lngt),
              };
              this.addCollectFence(data);
            }
          } else {
            if (this.activeName == "first") {
              let data = {
                id: this.id,
                CompanySite: this.form.zdname,
                companyId: this.form.name || 0,
                addressInfo: this.form.remark,
                range: this.form.number,
                latLongStr: JSON.stringify(this.form.lngone),
                isDelete: false,
              };
              this.upCollectFence(data);
            } else {
              let data = {
                id: this.id,
                CompanySite: this.form.zdname,
                companyId: this.form.name || 0,
                latLongStr: JSON.stringify(this.form.lngt),
                isDelete: false,
              };
              this.upCollectFence(data);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    edit(row) {
      this.addtype = 1;
      this.title = "编辑地址";
      this.id = row.id;
      this.dialogVisible = true;
      if (row.addressInfo) {
        this.activeName = "first";
        this.form.zdname = row.companySite;
        this.form.name = row.companyId;
        this.form.remark = row.addressInfo;
        this.form.number = row.range;
        this.form.lngone = JSON.parse(row.latLongStr);
        this.$nextTick(() => {
          this.$refs.serc.edit1(this.form.lngone);
        });
      } else {
        this.activeName = "second";
        this.form.name = row.companyId;
        this.form.zdname = row.companySite;
        this.form.lngt = JSON.parse(row.latLongStr);
        this.$nextTick(() => {
          this.$refs.serc.edit(this.form.lngt);
        });
      }
    },

    //UpCollectFence

    upCollectFence(data) {
      UpCollectFence(data)
        .then((res) => {
          console.log(res);
          if (res.code == 10000) {
            this.$message.success(res.msg);
            this.resetForm();
            this.getlist();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addCollectFence(data) {
      AddCollectFence(data)
        .then((res) => {
          console.log(res);
          if (res.code == 10000) {
            this.$message.success(res.msg);
            this.resetForm();
            this.getlist();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    lngdit(val) {
      this.form.lngt = val;
    },
    editremark(val, lng) {
      console.log(val, lng);
      this.form.lngone = lng;
      this.form.remark = val.district + val.address;
    },
    getlist() {
      let data = {
        str: this.input2,
        pageIndex: this.currentPage,
        pageSiz: this.pagesize,
      };
      GetCollectFenceList(data).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.total = res.total;
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getlist();
    },
    handleClose() {
      // this.$refs.serc.removepol()
      this.resetForm();
    },
    activechange() {},
    add() {
      this.reflash = false;
      this.$nextTick(() => {
        this.reflash = true;
      });
      this.title = "新增地址";
      this.activeName = "first";
      setTimeout(() => {
        this.$refs.serc.openadd();
      }, 800);
      this.addtype = 0;
      this.dialogVisible = true;
      this.$refs.form.clearValidate();
    },
    handleChange() {},
    delet(row) {
      this.$confirm("信息删除后无法恢复，确定要删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.isDelete = true;
          delete row.updateTime;
          UpCollectFence(row)
            .then((res) => {
              console.log(res);
              if (res.code == 10000) {
                this.$message.success(res.msg);
                this.resetForm();
                this.getlist();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.districon {
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 10px;
}
.btinput {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}
.btn {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  float: right;
}
.tablediv {
  margin-top: 15px;
}
.block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.footer {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.eltab /deep/ .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.amap {
  position: relative;
}
.tabdiv {
  margin-top: 20px;
  margin-left: 50px;
}
</style>