import request from '../utils/request'
import baseURL from '../utils/config'

//得到省市区
export function Province() {
    return request({
        url: baseURL.baseURL + 'api/Dic/GetProvinceCityArea',
        method: 'get',
    })
}
//收发件人列表
export function GetList(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PersonalAddress/GetList',
      method: 'get',
      params:data
  })
}
//新增或者编辑
export function AddOrEdit(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PersonalAddress/AddOrEdit',
      method: 'post',
      data
  })
}
//添加订单
export function AddOrderForPersonal(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/AddOrderForPersonal',
      method: 'post',
      data
  })
}
//商品信息列表
export function shopList() {
  return request({
      url: baseURL.baseURL + 'api/Adm_GoodsType/GetList',
      method: 'get',
  })
}
//得到详情
export function GetDetail(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PersonalAddress/GetDetail',
      method: 'get',
      params:data
  })
}

//编辑
export function EditOrderForPersonal(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/EditOrderForPersonal',
      method: 'post',
      data
  })
}

//编辑详情
export function bjdetail(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetOrderDetailForPersonal',
      method: 'get',
      params:data
  })
}

export function Delete(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PersonalAddress/Delete',
      method: 'get',
      params:data
  })
}